export const IMAGES = [
  { src: "https://i.ibb.co/mFDRBgg/image-7.png", thumbnail: "" },
  { src: "https://i.ibb.co/GTQrzPh/image.png", thumbnail: "" },
  { src: "https://i.ibb.co/m61Tkcs/1.png", thumbnail: "" },
  { src: "https://i.ibb.co/sPqK5w8/2.png", thumbnail: "" },
  { src: "https://i.ibb.co/2cJqPXM/3.png", thumbnail: "" },
  { src: "https://i.ibb.co/bvCvtfc/4.png", thumbnail: "" },
  { src: "https://i.ibb.co/99HnpnB/5.png", thumbnail: "" },
  // { src: "https://i.ibb.co/RQMSFWh/img68.png", thumbnail: "" },
  { src: "https://i.ibb.co/jVfW33b/img75.png", thumbnail: "" },
  { src: "https://i.ibb.co/rHfn2D4/img91.png", thumbnail: "" },
  { src: "https://i.ibb.co/ZfCXVK5/img108.png", thumbnail: "" },
  { src: "https://i.ibb.co/6vPdTzn/img109.png", thumbnail: "" },
  { src: "https://i.ibb.co/qDVX0jB/img43.png", thumbnail: "" },
  { src: "https://i.ibb.co/5ryF7h3/img44.png", thumbnail: "" },
  { src: "https://i.ibb.co/zGYLHRX/img46.png", thumbnail: "" },
  // { src: "https://i.ibb.co/VQDLw0f/img48.png", thumbnail: "" },
  { src: "https://i.ibb.co/80C0c3k/img52.png", thumbnail: "" },
  // { src: "https://i.ibb.co/VMPtnFV/img53.png", thumbnail: "" },
  // { src: "https://i.ibb.co/hZMZz47/img54.png", thumbnail: "" },
  { src: "https://i.ibb.co/2ds7PJ2/img61.png", thumbnail: "" },
  { src: "https://i.ibb.co/vPWFwfs/img65.png", thumbnail: "" },
  // { src: "https://i.ibb.co/44nRtLp/img69.png", thumbnail: "" },
  { src: "https://i.ibb.co/Wf2Ryr8/img74.png", thumbnail: "" },
  { src: "https://i.ibb.co/F6HnPnT/img84.png", thumbnail: "" },
  { src: "https://i.ibb.co/tB5z8vp/img89.png", thumbnail: "" },
  // { src: "https://i.ibb.co/n88Yzmb/img90.png", thumbnail: "" },
  { src: "https://i.ibb.co/hVR22Vh/img96.png", thumbnail: "" },
  { src: "https://i.ibb.co/727LKv1/img100.png", thumbnail: "" },
  { src: "https://i.ibb.co/YpKGRmM/img101.png", thumbnail: "" },
  { src: "https://i.ibb.co/FxgzVkn/img107.png", thumbnail: "" },
  { src: "https://i.ibb.co/r41F8HQ/img45.png", thumbnail: "" },
  { src: "https://i.ibb.co/S0GVMN1/img58.png", thumbnail: "" },
  { src: "https://i.ibb.co/Lvj27Cp/img66.png", thumbnail: "" },
  { src: "https://i.ibb.co/80T7t5Z/img67.png", thumbnail: "" },
  { src: "https://i.ibb.co/yNsS9rq/img76.png", thumbnail: "" },
  // { src: "https://i.ibb.co/SvHkNNj/img82.png", thumbnail: "" },
  { src: "https://i.ibb.co/F6GgS29/img83.png", thumbnail: "" },
  { src: "https://i.ibb.co/XjD1frf/img88.png", thumbnail: "" },
  { src: "https://i.ibb.co/Zfc0LJN/img92.png", thumbnail: "" },
  { src: "https://i.ibb.co/s5fjnK5/img98.png", thumbnail: "" },
  // { src: "https://i.ibb.co/82zxV8y/img102.png", thumbnail: "" },
  // { src: "https://i.ibb.co/2WKFf1L/img106.png", thumbnail: "" },
  { src: "https://i.ibb.co/WPVG2Hm/img47.png", thumbnail: "" },
  { src: "https://i.ibb.co/1nwVJsJ/img59.png", thumbnail: "" },
  { src: "https://i.ibb.co/dKg4tQx/img60.png", thumbnail: "" },
  // { src: "https://i.ibb.co/sp4n6hs/img73.png", thumbnail: "" }
  { src: "https://i.ibb.co/S5QCf5R/image1.jpg", thumbnail: "" },
  { src: "https://i.ibb.co/bdfymD9/image2.jpg", thumbnail: "" },
  { src: "https://i.ibb.co/s3QFz7L/image3.jpg", thumbnail: "" },
  { src: "https://i.ibb.co/NCB3tJB/15.png", thumbnail: "" },
  { src: "https://i.ibb.co/WpB4Dpp/14.png", thumbnail: "" },
  { src: "https://i.ibb.co/YhgTtBF/13.jpg", thumbnail: "" },
  { src: "https://i.ibb.co/VLmrQKT/12.png", thumbnail: "" },
  { src: "https://i.ibb.co/tpkS6kt/11.jpg", thumbnail: "" },
  { src: "https://i.ibb.co/sm8DnpR/10.jpg", thumbnail: "" },
  { src: "https://i.ibb.co/pvs5BKd/9.png", thumbnail: "" },
  { src: "https://i.ibb.co/0ZHZ8Tz/8.png", thumbnail: "" },
  { src: "https://i.ibb.co/8jBGMH8/7.png", thumbnail: "" },
  { src: "https://i.ibb.co/qCCvdxG/6.png", thumbnail: "" },
  { src: "https://i.ibb.co/PGnxbYH/5.png", thumbnail: "" },
  { src: "https://i.ibb.co/xjdXW29/4.png", thumbnail: "" },
  { src: "https://i.ibb.co/3rRrHGq/3.png", thumbnail: "" },
  { src: "https://i.ibb.co/PGXhqSw/2.png", thumbnail: "" },
  { src: "https://i.ibb.co/k8jN3Qb/1.png", thumbnail: "" }
];
